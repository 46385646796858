import { useDispatch } from 'react-redux';
import React, { FC } from 'react';

import { ApiTransactionSummary, Organization } from 'store/api/api.types';
import { platformApi, useCancelTransactionMutation } from 'store/api/platformApi';
import { showError, showSuccess } from 'services/notificationService';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalCreateKeyTitle = 'Create API Key';

const ModalCancelTransaction: FC<{
  organizationId: Organization['id'];
  transactionId: ApiTransactionSummary['id'];
}> = ({ organizationId, transactionId }) => {
  const [mutation] = useCancelTransactionMutation();
  const dispatch = useDispatch();

  const handleCancelTransaction = async () => {
    const response = await mutation({ organizationId, transactionId });

    if (!('error' in response)) {
      showSuccess(<>This transaction has been successfully cancelled.</>);

      setTimeout(() => {
        dispatch(
          platformApi.util.invalidateTags([
            { id: organizationId, type: 'TransactionSummaries' },
            { id: transactionId, type: 'TransactionSummary' },
            { id: organizationId, transactionId, type: 'TransactionEvents' } as any,
          ]),
        );
      }, 2000);
    } else {
      showError(
        <>
          There was an error attempting to cancel this transaction, if you encounter this issue
          again please reach out to support.
        </>,
      );
    }

    modalService.closeCurrentModal();
  };

  return (
    <form onSubmit={handleCancelTransaction}>
      <ModalContent variant='default'>
        <Text variant='bodyCopyStandard'>
          Cancelling this transaction will halt it immediately, and it will not be processed. This
          action is irreversible. If you wish to proceed with your exchange later, you will need to
          initiate a new transaction.
        </Text>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          label='Go back'
          onClick={() => {
            modalService.closeCurrentModal();
          }}
          variant='textCta'
        />
        <Button label='Confirm Cancellation' type='submit' variant='destructive' />
      </ModalContent>
    </form>
  );
};

export default ModalCancelTransaction;
