import { AssetTypeUri, FiatTicker } from 'constants/assetTypes.types';
import { BankAccountType } from 'components/dedicated/organization/senders-recipients/config/bankDetails.types';
import { CountryCode } from 'constants/countries';

export interface ErrorResponse {
  data: {
    errors: ResponseError[];
  };
  status: number;
}

export interface Chain {
  explorerUrl: string;
  fungibleToken: string;
  id: string;
  name: string;
}

export interface ResponseError {
  code: ErrorCode;
  detail?: string;
  meta?: Record<string, any>;
  source?: {
    parameter?: string;
    pointer?: string;
  };
  title?: string;
}

export interface LocalFormError {
  fieldName: string;
  message: string;
}

export type LocalFormErrors = LocalFormError[];

export enum ErrorCode {
  ACCOUNT_LINKING_REQUIRED = 'ACCOUNT_LINKING_REQUIRED',
  DUPLICATED_VALUE = 'DUPLICATED_VALUE',
  INVALID_OPERATION = 'INVALID_OPERATION',
  INVALID_VALUE = 'INVALID_VALUE',
  MISSING_VALUE = 'MISSING_VALUE',
  NODE_NOT_FOUND = 'NODE_NOT_FOUND',
}

export type ErrorMessagesMap = Partial<Record<ErrorCode, string>>;

export type RequestFilterParam = Record<string, any>;

export enum PaginationMode {
  Cursor = 'cursor',
  Offset = 'offset',
}

export interface CursorPaginationPageParam {
  after?: string;
  before?: string;
  mode: PaginationMode.Cursor;
  size?: number;
}

export interface OffsetPaginationPageParam {
  mode: PaginationMode.Offset;
  number?: string;
  size?: number;
}

export interface ApiRequestParams {
  filter?: RequestFilterParam;
  page?: CursorPaginationPageParam | OffsetPaginationPageParam;
  sort?: string;
}

export interface ApiFilterParams {}

export interface ApiIdentityFilterParams extends ApiFilterParams {
  businessName?: string;
  firstName?: string;
  lastName?: string;
}

export interface Links {
  last?: string;
  next?: string;
  prev?: string;
}

export interface ApiPagedResponse<T> {
  data: T[];
  links: Links;
}

export interface Permission {
  actions: string[];
  constraints: Record<string, any>[];
  resources: string[];
}

export interface Policy {
  boundaries: Record<string, any>[];
  createdByUserId?: string;
  id: string;
  name: string;
  organizationId?: string;
  permissions: Permission[];
  source: 'organization' | 'system';
}

export interface PortalStatus {
  isNominal: boolean;
  isUnderMaintenance: boolean;
}

export interface CurrentUser {
  auth0Id: string;
  authorizations: {
    combinedPolicies: Policy[];
  };
  email: string;
  id: string;
  organizations: string[];
}

export interface User {
  auth0Id: string;
  id: string;
}

export interface OrganizationMember {
  email: string;
  id: string;
  roles: OrganizationRole[];
}

export interface OrganizationMembersResponse {
  data: OrganizationMember[];
  links: Links;
}

export interface OrganizationRole {
  id: string;
  name: string;
}

export type Organizations = Pick<Organization, 'id' | 'name'>[];

export interface PlatformFeatureFlags {
  clientTransactionsReportEnabled: boolean;
}
export interface PlatformAttributes {
  features: PlatformFeatureFlags;
  primaryAccount: string | null;
  tags: ClientTag[];
}

export interface Platform {
  attributes?: PlatformAttributes;
  canUsePlatform: boolean;
  clientId: string | null;
  hasAccess: boolean;
}

export enum ClientTag {
  COLUMN = 'COLUMN',
  CRYPTO = 'CRYPTO',
  NESTED = 'NESTED',
}

export interface OrganizationAgreementDecision {
  acceptedAt: Date | null;
  agreementId: string;
  email: string | null;
  userId: string | null;
}

export interface Organization {
  agreements: OrganizationAgreementDecision[];
  id: string;
  name: string;
  platform: Platform;
}

export interface AccountAttributesBalance {
  amount: string;
  assetType: string;
}

export interface AccountAttributesBalances {
  available: AccountAttributesBalance[];
  pending: AccountAttributesBalance[];
}

export interface AccountAttributes {
  balances: AccountAttributesBalances;
  status: string;
}

export interface AccountData {
  assetPairs?: AccountAssetPair[];
  attributes: AccountAttributes;
  balances?: AccountBalances;
  id: string;
  name?: string;
}

export interface KeyAttributes {
  bearerURI: string;
  createdAt: string;
  hashedSecret?: string;
  salt?: number;
  secret?: string;
  updatedAt: string;
}

export interface KeyData {
  attributes: KeyAttributes;
  id: string;
}

export interface AccountIncludedAttributes {
  accountId?: string;
  address?: string;
  assetType?: string;
  balances?: AccountBalances;
  description?: string;
  function?: string;
  name?: string;
  networkId?: string;
  poolId?: string;
  poolName?: string;
  poolType?: string;
}

export interface AccountIncluded {
  attributes: AccountIncludedAttributes;
  id: string;
  type: string;
}

export interface Account {
  data: AccountData;
  included: AccountIncluded[];
}

export interface AccountAssetPair {
  from: string;
  to: string;
}

export interface AccountBalances {
  available: AccountBalance[];
  pending: AccountBalance[];
}

export interface AccountBalance {
  amount: string;
  assetType: string;
}

export interface BankAccountRequest {
  data: BankAccountRequestData;
  organizationId: Organization['id'];
}

export interface BankAccounts {
  data: BankAccount[];
  links: Links;
}

export interface BankAccount {
  attributes: BankAccountAttributes;
  id: string;
  relationships: BankAccountRelationships;
}

export interface BankAccountRequestData {
  attributes: BankAccountAttributes;
  relationships: BankAccountRelationships;
  type: 'bank-account';
}

export interface BankAccountRelationships {
  accountHolders?: BankAccountRelationshipsAccountHolder;
  identities: BankAccountRelationshipsIdentity;
}

interface BankAccountRelationshipsAccountHolder {
  data: BankAccountRelationshipsAccountHoldersData[];
}

interface BankAccountRelationshipsAccountHoldersData {
  id: string;
}

export interface BankAccountRelationshipsIdentity {
  data: BankAccountRelationshipsIdentityData[];
}

interface BankAccountRelationshipsIdentityData {
  id: string;
  type: 'identity';
}

export interface BankAccountAttributes {
  accountNumber: string;
  accountType: BankAccountType | '';
  bankName: string;
  bicSwiftCode?: string;
  country: CountryCode | '';
  currency: FiatTicker | '';
  id?: string;
  nameOnBankAccount: string;
  routingCode?: string;
}

export interface LiquidityLimitAttributes {
  assetType: string;
  date: Date;
  limitEnabled: boolean;
  liquidityLimit: number;
  liquidityUtilization: number;
}

export interface LiquidityLimit {
  attributes: LiquidityLimitAttributes;
  type: 'daily';
}

export type LiquidityLimits = LiquidityLimit[];

export interface TradingPair {
  enabled: boolean;
  source: AssetTypeUri;
  target: AssetTypeUri;
}

export interface TradingPairs {
  data: TradingPair[];
}

export interface TransactionAddress {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  street: string;
}

export interface TransactionAccountHolder {
  address: TransactionAddress;
  name: string;
  taxNumber: string;
}

export interface TransactionBank {
  accountNumber?: string;
  address?: TransactionAddress;
  branchCode: string;
  clabe?: string;
  intermediarySwiftOrBic?: string;
  name: string;
  routingCode?: string;
  swiftCode?: string;
  swiftOrBic?: string;
}

export enum TransactionDepositInstructionsType {
  International = 'international',
  Local = 'local',
  PIX = 'pix',
  SPEI = 'spei',
  TED = 'ted',
}

export type TransactionDepositInstructions =
  | TransactionDepositInstructionsDefault
  | TransactionDepositInstructionsForPix
  | TransactionDepositInstructionsForSpei
  | TransactionDepositInstructionsForTed;

export interface TransactionDepositInstructionsDefault {
  accountHolder?: TransactionAccountHolder;
  assetType: string;
  bank: TransactionBank;
  reference?: string;
  type: TransactionDepositInstructionsType.International | TransactionDepositInstructionsType.Local;
}

export interface TransactionDepositInstructionsForPix {
  code: string;
  expiresAt?: string;
  key: string;
  qrCodeImageBase64: string;
  type: TransactionDepositInstructionsType.PIX;
}

export interface TransactionDepositInstructionsForSpei {
  bank: string;
  beneficiary: string;
  clabe: string;
  type: TransactionDepositInstructionsType.SPEI;
}

export interface TransactionDepositInstructionsForTed {
  accountHolder: string;
  accountNumber: string;
  agencyCode: string;
  bankName: string;
  taxNumber: string;
  type: TransactionDepositInstructionsType.TED;
}

export enum TransactionStatus {
  AwaitingComplianceReview = 'awaiting_compliance_review',
  AwaitingFunds = 'awaiting_funds',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ComplianceApproved = 'compliance_approved',
  ComplianceRejected = 'compliance_rejected',
  Created = 'created',
  FundsReceived = 'funds_received',
  InComplianceReview = 'in_compliance_review',
  PaymentProcessed = 'payment_processed',
  ProcessingPayment = 'processing_payment',
  ProcessingSettlement = 'processing_settlement',
  ProcessingWithdrawal = 'processing_withdrawal',
  SettlementProcessed = 'settlement_processed',
  WithdrawalProcessed = 'withdrawal_processed',
}

export enum TransactionType {
  Deposit = 'deposit',
  Fx = 'fx',
  Offramp = 'offramp',
  Onramp = 'onramp',
  Withdrawal = 'withdrawal',
}

export interface TransactionAttributes {
  amount: string;
  assetType: string;
  createdAt: string;
  createdBy: string;
  depositInstructions?: TransactionDepositInstructions[];
  destination: string;
  effectiveDate: string;
  quote?: string;
  settleAt?: 'day_close' | 'now';
  source: string;
  status: TransactionStatus;
}

export interface TransactionRelationshipsQuoteData {
  id: string;
}

export interface TransactionRelationshipsQuote {
  data: TransactionRelationshipsQuoteData;
}

export interface TransactionRelationships {
  quote: TransactionRelationshipsQuote;
}

export interface Transaction {
  attributes: TransactionAttributes;
  id: string;
  relationships: TransactionRelationships;
}

export interface TransactionList {
  data: Transaction[];
  links: Links;
}

export interface TransactionDetail {
  data: Transaction;
}

export enum TransactionPurpose {
  InterCompanyTransfer = 'InterCompanyTransfer',
  Investments = 'Investments',
  Other = 'Other',
  PaymentsForGoodsOrServices = 'PaymentsForGoodsOrServices',
  Payroll = 'Payroll',
}

export interface TransactionRequest {
  amount: string;
  assetType: string;
  bank?: string;
  destination: string;
  file?: File;
  organizationId: Organization['id'];
  purpose?: TransactionPurpose;
  quote?: string;
  reference?: string;
  source: string;
}

enum QuoteStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Filled = 'filled',
  Open = 'open',
}

export interface QuoteAssetEntry {
  amount: string;
  assetType: string;
}

export interface QuoteAttributes {
  createdAt: Date;
  expiresAt: Date;
  source: QuoteAssetEntry;
  sourceBankId?: string;
  status: QuoteStatus;
  target: QuoteAssetEntry;
  targetBankId?: string;
}

export interface QuoteDetail {
  data: Quote;
}

export interface Quote {
  attributes: QuoteAttributes;
  id: string;
  type: 'quote';
}

export interface QuoteRequest {
  organizationId: Organization['id'];
  recipient?: string;
  sourceAmount: string | undefined;
  sourceAssetType: string;
  sourceBankId?: string;
  targetAmount: string | undefined;
  targetAssetType: string;
  targetBankId?: string;
}

export enum InviteStatus {
  Accepted = 'accepted',
  Expired = 'expired',
  Pending = 'pending',
}
export interface Invite {
  createdAt: string;
  email: string;
  expiresAt: string;
  hash: string;
  id: string;
  isAccepted: boolean;
  organizationId: string;
  roleId: string;
  status: InviteStatus;
}

export interface OrganizationInvitesResponse {
  data: Invite[];
  links: Links;
}

export interface Identities {
  data: Identity[];
  links: Links;
}

export interface IdentityRequest {
  data: IdentityAttributes;
  organizationId: Organization['id'];
}

export interface IdentityAttributes {
  address: IdentityAttributesAddress;
  businessName: string;
  email: string;
  externalId?: string;
  firstName: string;
  identificationNumber?: string;
  identificationType?: string;
  lastName: string;
  middleName?: string;
  nationality?: CountryCode | '';
  phone: string;
  verificationStatus?: string;
}

export interface IdentityAttributesAddress {
  address: string;
  aptUnitSuiteFloor?: string;
  city: string;
  country: CountryCode | '';
  postalCode: string;
  stateProvince?: string;
  streetName?: string;
  streetNumber?: string;
}

/**
 * this interface represents a simplified version of the bank account entity that is returned
 * in the /identities endpoint.
 */
export interface IdentityBankAccount {
  accountNumber: string;
  accountType: BankAccountType;
  assetType: FiatTicker;
  bankName: string;
  bicSwiftCode?: string;
  country: CountryCode;
  id: string;
  intermediaryBankName: string;
  intermediaryBic: string;
  nameOnBankAccount: string;
  routingCode: string;
}

export interface Identity {
  accountIds: string[];
  address: string;
  addressAptUnitSuiteFloor: string;
  addressCity: string;
  addressCountry: CountryCode;
  addressPostalCode: string;
  addressStateProvince: string;
  addressStreetName: string;
  addressStreetNumber: string;
  bankAccountIds: string[];
  bankAccountNumbers: string[];
  bankAccounts: IdentityBankAccount[];
  businessName: string;
  clientId: string;
  clientName: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  id?: string;
  lastName: string;
  middleName: string;
  nationality: CountryCode;
  phone: string;
  status: ApiIdentityStatus;
  taxNumber: string;
}

export enum WalletStatus {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export enum ApiIdentityStatus {
  APPROVED = 'approved',
  CREATED = 'created',
  ENABLED = 'enabled',
  PENDING = 'pending',
  REJECTED = 'rejected',
  SCREENED = 'screened',
}

export interface WalletAttributes {
  address: string;
  assetType: string;
  depositInstructions?: TransactionDepositInstructionsDefault[];
  function: 'client-custody';
  networkId: string;
  status: WalletStatus;
}

export interface Wallet {
  attributes: WalletAttributes;
  id: string;
  type: 'wallet';
}

export interface Wallets {
  data: Wallet[];
  links: Links;
}

export interface ApiTransactionSummaryAttributesBank {
  accountNumber: string;
  bankName: string;
  nameOnBankAccount: string;
}

export interface ApiTransactionSummaryAttributesSource {
  amount: string;
  assetType: string;
  bank?: ApiTransactionSummaryAttributesBank;
  bankAccountHolder?: {
    businessName: string;
    firstName: string;
    lastName: string;
  };
  id: string;
}

interface ApiTransactionSummaryAttributesDestination {
  amount: string;
  assetType: string;
  bank?: ApiTransactionSummaryAttributesBank;
  id: string;
}

interface ApiTransactionSummaryAttributesQuote {
  createdAt: string;
  expiresAt: string;
  finalVendorSourceAmount: string;
  finalVendorTargetAmount: string;
  id: string;
  initialVendorSourceAmount: string;
  initialVendorTargetAmount: string;
  rate: string;
  status: string;
}

interface ApiTransactionSummaryAttributesRamp {
  depositInstructions?: TransactionDepositInstructions[];
}

interface ApiTransactionSummaryAttributesFx {
  payInInstructions?: TransactionDepositInstructions[];
}

export interface ApiTransactionSummaryAttributes {
  blockchainTxHash?: string;
  cancellable?: boolean;
  clientName: string;
  createdAt: string;
  destination: ApiTransactionSummaryAttributesDestination;
  documents?: {
    invoice: {
      fileName: string;
      id: string;
    };
  };
  effectiveAt: string;
  fx: ApiTransactionSummaryAttributesFx;
  purpose?: string;
  quote: ApiTransactionSummaryAttributesQuote;
  ramp: ApiTransactionSummaryAttributesRamp;
  reference?: string;
  source: ApiTransactionSummaryAttributesSource;
  status: TransactionStatus;
  transactionType: TransactionType;
}

export interface ApiTransactionSummary {
  attributes: ApiTransactionSummaryAttributes;
  id: string;
  type: 'transaction-summary';
}

export interface ApiTransactionSummaries extends ApiPagedResponse<ApiTransactionSummary> {}

export interface TransactionEvent {
  attributes: {
    createdAt: string;
    newStatus: TransactionStatus;
    oldStatus?: TransactionStatus;
    type: 'transaction_status_updated';
  };
  id: string;
  type: 'transaction-event';
}

export interface TransactionEventsRepsonse {
  data: TransactionEvent[];
  links: Links;
}
