import React, { ReactNode } from 'react';

import { CancelTransactionButtonProps } from 'components/dedicated/CancelTransactionButton/CancelTransactionButton.types';
import { TransactionStatus } from 'store/api/api.types';
import Button from 'components/core/Button/Button';
import ModalCancelTransaction from 'components/dedicated/ModalCancelTransaction/ModalCancelTransaction';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

export const CancelTransactionButton = ({
  transactionId,
  organizationId,
  transactionStatus,
  ...buttonProps
}: CancelTransactionButtonProps): ReactNode => {
  const cancellableStatus = [
    TransactionStatus.AwaitingFunds,
    TransactionStatus.AwaitingComplianceReview,
    TransactionStatus.InComplianceReview,
    TransactionStatus.Created,
    TransactionStatus.ProcessingWithdrawal,
  ];

  const isDisabled = !cancellableStatus.includes(transactionStatus) || buttonProps.isDisabled;
  return (
    <Tooltip isHidden={!isDisabled} label='This transaction can no longer be cancelled'>
      <Button
        isDisabled={isDisabled}
        variant='destructiveSecondary'
        {...buttonProps}
        onClick={() =>
          modalService.openModal(
            { title: 'Are you sure?' },
            <ModalCancelTransaction
              organizationId={organizationId}
              transactionId={transactionId}
            />,
          )
        }
      >
        Cancel Transaction
      </Button>
    </Tooltip>
  );
};
