import React, { FC, useMemo, useState } from 'react';

import { SlideoutTransactionDetailsProps } from 'components/dedicated/SlideoutTransactionDetails/SlideoutTransactionDetails.types';
import { SlideoutTransactionDetailsTabName } from 'components/dedicated/SlideoutTransactionDetails/SlideoutTransactionDetailsHeader/SlideoutTransactionDetailsHeader.types';
import { useGetTransactionSummaryQuery } from 'store/api/platformApi';
import TransactionActivity from 'components/dedicated/TransactionActivity/TransactionActivity';
import TransactionDetails from 'components/dedicated/TransactionDetails/TransactionDetails';

import SlideoutTransactionDetailsHeader from './SlideoutTransactionDetailsHeader/SlideoutTransactionDetailsHeader';

const SlideoutTransactionDetails: FC<SlideoutTransactionDetailsProps> = ({
  organizationId,
  transactionSummary,
}) => {
  const { data: separatelyDownloadedTransactionSummary } = useGetTransactionSummaryQuery({
    organizationId,
    transactionId: transactionSummary.id,
  });
  const latestTransactionSummary = useMemo(() => {
    return separatelyDownloadedTransactionSummary || transactionSummary;
  }, [separatelyDownloadedTransactionSummary, transactionSummary]);

  const [currentTab, setCurrentTab] = useState<SlideoutTransactionDetailsTabName>('activity');
  return (
    <>
      <SlideoutTransactionDetailsHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        transactionSummary={latestTransactionSummary}
      />
      {currentTab === 'activity' && (
        <TransactionActivity
          organizationId={organizationId}
          padding={6}
          transactionSummary={latestTransactionSummary}
        />
      )}
      {currentTab === 'details' && (
        <TransactionDetails
          organizationId={organizationId}
          padding={6}
          transactionSummary={latestTransactionSummary}
        />
      )}
    </>
  );
};

export default SlideoutTransactionDetails;
